.contentWrapperForProductLayout {
  display: flex;
  gap: 100px;
  justify-content: center;
  background-color: #fff;
  padding-top: 40px;
  padding-bottom: 60px;
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 0px;
    padding-top: 0px;
  }

  & > div {
    width: 30%;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  & .orderColumnForProductLayout {
    @media (max-width: 767px) {
      padding: 22px;
    }
  }
}
